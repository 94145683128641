import 'whatwg-fetch'
import {
  CHAT_ALIGN_LEFT_CLASS,
  CHAT_ALIGN_RIGHT_CLASS,
  CHAT_CLASS,
  CHAT_CLOSED_CLASS,
  CHAT_HIDDEN_CLASS,
  CHAT_OPENED_HEIGHT,
  CHAT_OPENED_HEIGHT_GHOST_MESSAGE_MOBILE,
  CHAT_GHOST_MESSAGE_MOBILE,
  CHAT_OPENED_WIDTH,
  CHAT_CLOSED_HEIGHT,
  CHAT_CLOSED_WIDTH,
  CHAT_OPEN_CLASS,
  CHAT_VISIBLE_CLASS,
  CHAT_Z_INDEX,
  COMMUNICATOR_SCRIPT_ATTRIBUTE_NAME,
  FRAME_INITIAL_URL,
  FRAME_NAME,
  MOUNTPOINT_NAME,
  CHAT_LAUNCHER_HIDDEN_CLASS,
  CHAT_OPENED_HIDDEN_LAUNCHER_HEIGHT,
  CHAT_OPENED_HIDDEN_LAUNCHER_WIDTH,
  CHAT_LAUNCHER_VISIBLE_CLASS,
  CHAT_FULLSCREEN_CLASS,
} from '@/common/common-constants'

export function createCommunicatorScript(url) {
  const communicatorScript = window.document.createElement('script')

  communicatorScript.setAttribute('type', 'text/javascript')
  communicatorScript.setAttribute('src', url)
  communicatorScript.setAttribute(`${COMMUNICATOR_SCRIPT_ATTRIBUTE_NAME}`, true)

  return communicatorScript
}

export function createFrame(
  initialUrl = FRAME_INITIAL_URL,
  name = FRAME_NAME,
  launcherHidden = false,
  leftSideChatbot = false,
) {
  const frame = window.document.createElement('iframe')

  frame.setAttribute('src', initialUrl)
  frame.setAttribute('name', name)

  // Set 'allow-same-origin' as we do not have the posibility to set document.domain in the iframe dynamically,
  // this enables us to work around a same-origin policy issue when the iFrame tries to access it's parents document/window
  // See here for more: https://javascript.info/cross-window-communication#the-sandbox-iframe-attribute
  frame.setAttribute(
    'sandbox',
    'allow-scripts allow-downloads allow-forms allow-same-origin allow-top-navigation-by-user-activation allow-popups allow-popups-to-escape-sandbox allow-presentation',
  )

  // Defaults: Closed, hidden chat, launcher enabled
  frame.classList.add(CHAT_CLASS, CHAT_HIDDEN_CLASS, CHAT_CLOSED_CLASS)

  if (launcherHidden) {
    frame.classList.add(CHAT_LAUNCHER_HIDDEN_CLASS)
  } else {
    frame.classList.add(CHAT_LAUNCHER_VISIBLE_CLASS)
  }

  if (leftSideChatbot) {
    frame.classList.add(CHAT_ALIGN_LEFT_CLASS)
  } else {
    frame.classList.add(CHAT_ALIGN_RIGHT_CLASS)
  }

  frame.style.backgroundColor = 'rgba(0, 0, 0, 0)'

  return frame
}

export function createMountpoint(isRelativePositioned = false) {
  const mountpoint = window.document.createElement('div')

  mountpoint.setAttribute('id', MOUNTPOINT_NAME)
  mountpoint.setAttribute('aria-live', 'polite')

  if (isRelativePositioned) {
    mountpoint.classList.add('relative')
  }

  return mountpoint
}

export function createStyles() {
  const styles = window.document.createElement('style')

  styles.setAttribute('type', 'text/css')

  const css = `
#${MOUNTPOINT_NAME}{position:relative;height:0;bottom:0;width:100%}
#${MOUNTPOINT_NAME}.relative{height:auto;display:flex;justify-content:center;align-items: center;}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}]{position:fixed;bottom:0;width:0;height:0;background-color:transparent;display:none;z-index:${CHAT_Z_INDEX};border:0}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_ALIGN_RIGHT_CLASS}{right:0 !important}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_ALIGN_LEFT_CLASS}{left:0 !important}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_OPEN_CLASS}{max-height:100%;max-width:100%;height:${CHAT_OPENED_HEIGHT};width:${CHAT_OPENED_WIDTH}}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_OPEN_CLASS}.${CHAT_GHOST_MESSAGE_MOBILE}{max-height:100%;max-width:100%;height:${CHAT_OPENED_HEIGHT_GHOST_MESSAGE_MOBILE};width:300px}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_OPEN_CLASS}.${CHAT_LAUNCHER_HIDDEN_CLASS}{max-height:100%;max-width:100%;height:${CHAT_OPENED_HIDDEN_LAUNCHER_HEIGHT};width:${CHAT_OPENED_HIDDEN_LAUNCHER_WIDTH}}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_OPEN_CLASS}.${CHAT_FULLSCREEN_CLASS}{height:100vh;width:100vw;position:fixed;z-index:99999;}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_CLOSED_CLASS}{height:${CHAT_CLOSED_HEIGHT};width:${CHAT_CLOSED_WIDTH}}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_CLOSED_CLASS}.${CHAT_LAUNCHER_HIDDEN_CLASS}{height:0;width:0}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_VISIBLE_CLASS}{display:block!important}
#${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}].${CHAT_HIDDEN_CLASS}{display:none!important}
#${MOUNTPOINT_NAME}.relative iframe[name=${FRAME_NAME}]{position:relative;}
@media (max-width: 768px) { #${MOUNTPOINT_NAME} iframe[name=${FRAME_NAME}]{width:100%;margin-top:30px} }`

  styles.innerHTML = css

  return styles
}

export async function fetchIntegratorConfig(
  integrationConfigPath,
  integratorName,
) {
  return fetch(`${integrationConfigPath}/${integratorName}.json`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      // If there was an error (eg 400) and the config could not be loaded, then
      // no proxy config will be
      if (!response.ok) {
        return null
      }

      return response.json()
    })
    .catch((error) => {
      throw error
    })
}
